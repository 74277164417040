<template>
	<main class="dgrid-body" id="press-room">
		<div class="stack-vertical press-room-container">
			<h2 class="view-title">Press room</h2>
			<div v-for="pk in pressKits" class="stack-vertical press-room-item-container">
				<div class="stack-vertical press-room-text">
					<h4>{{pk.title}}</h4>
					<p class="text--body1">{{pk.description}}</p>
				</div>
				<base-button :text="$t('press_kit')" type="tertiary" @clicked="downloadPressKit($event, pk.id)"/>
			</div>
		</div>
	
		<div class="view-bottom-margin"></div>
		
	</main>
</template>

<script>

export default {
	name: 'PressRoom',
	data() {
		return {
			pressKits: [
				{title: 'Nuovo GeCA, il lancio', description: 'Il nuovo portale del GeCA mappa e racconta la cultura alimentare italiana', id: 12},
				{title: 'Villaurbana, la Casa del Pane', description: 'Geoportale della Cultura Alimentare per la Sardegna. Il patrimonio immateriale alimentare: il pane artigianale del Campidano. 14 luglio 2022, presso il cortile esterno della Casa del Pane di Villaurbana ', id: 1},
				{title: 'Galbiate, Museo Etnografico dell’Alta Brianza', description: 'Geoportale della Cultura Alimentare. Il patrimonio immateriale: strumenti e strategie di valorizzazione nella contemporaneità. Nord e Sud: esperienze a confronto', id: 2},
				{title: 'Sant’Anatolia di Narco', description: 'Geoportale della Cultura Alimentare: La (ri)scoperta dell’Umbria Patrimonio immateriale e saper fare per la valorizzazione territoriale Sant’Anatolia di Narco', id: 3},
				{title: 'Biella, BIS – weekend del gusto', description: 'Un nuovo appuntamento per promuovere la Cultura Alimentare del biellese ', id: 4},
				{title: 'Matera, Museo Archeologico Nazionale', description: 'Il Geoportale della Cultura Alimentare: cibo e territorio lucano ', id: 5},
				{title: 'Forlimpopoli, Casa Artusi', description: 'Il patrimonio epistolare di Pellegrino Artusi ora fruibile sul sito di Casa Artusi e all’interno del Geoportale della Cultura Alimentare, che dedicherà una nuova sezione all’opera del gastronomo forlimpopolese, arricchita da contenuti esclusivi. ', id: 6},
				{title: 'Lecce, Museo Castromediano', description: 'Il Geoportale della Cultura Alimentare fa tappa a Lecce', id: 7},
				{title: 'Verona, Tocatì', description: 'Il Geoportale della Cultura Alimentare a Tocatì', id: 8},
				{title: 'Bra, cheese', description: 'Il Geoportale della Cultura Alimentare a Cheese', id: 9},
				{title: 'Pergola, Fuera del Tartufo', description: 'Il Geoportale della Cultura Alimentare, il tartufo e la regione marche ', id: 10},
				{title: 'Genova, Slow Fish', description: 'Il Geoportale della Cultura Alimentare a slow fish Genova', id: 11},
			]
		}
	},
	methods: {
		async downloadPressKit(event, id) {
			console.log('id', id)
			let url = './press_kits/'+id+'.zip'
			console.log('url', url)
			let response = await fetch(url)
			let blob = await response.blob()
			let a = document.createElement("a");
			a.download = 'download.zip';
			a.download = 'download.zip';
			a.href = URL.createObjectURL(new Blob([blob], {type: 'application/zip'}));
			// a.download = "";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}
}
</script>

<style lang="scss">
	#press-room {
		gap: var(--s5);
	}

	.press-room-container {
		gap: var(--s5);
	}

	.press-room-item-container {
		gap: var(--s3);		
	}

	.press-room-text {
		gap: var(--s2);
	}
</style>

